<template>
  <a-modal
    v-model="custTagsVisible"
    title="自定义指标"
    @onCancel="handleCancel"
    @ok="handleSubmit"
    :width="828"
    dialogClass="cust-tags-modal"
  >
    <div class="cust-tags-model-container">
      <div class="cust-tags-container-left">
        <div class="cust-tags-contral">
          <a-checkbox
            class="checkbox child"
            :checked="checkedAll"
            :indeterminate="indeterminateAll"
            @click="changeAllSelection"
          >全选</a-checkbox
          >
          <span class="primary-text btn child" @click="changeInvertSelection">反选</span>
          <span class="primary-text btn child" @click="changeDefaultSelection">默认指标</span>
        </div>
        <div class="tag-group-wrapper">
          <div class="tag-group-box" v-for="(item, index) in targetArrTemp" :key="index">
            <div class="tag-group-head">
              <a-checkbox
                class="tag-group-head-checkbox"
                @change="changeIndeter($event, index)"
                :checked="item.checkAll"
                :indeterminate="item.indeterminate"
              >{{ item.title }} {{ item.count }}/{{ item.list.length }}</a-checkbox
              >
            </div>
            <div class="tag-group-list-box">
              <a-checkbox
                class="checkbox_item"
                size="small"
                @change="changeTag($event, index, c)"
                :checked="child.checked"
                v-for="(child, c) in item.list"
                :key="c"
              >
                <m-tips :content="child.desc" :title="child.label" :width="205"></m-tips>
              </a-checkbox>
            </div>
          </div>
        </div>
      </div>
      <div class="cust-tags-container-right">
        <div class="cust-tags-container-right-head">
          <span>已选字段（{{ sureArrTemp.length }}）</span>
        </div>
        <m-vuedraggable v-model="sureArrTemp" animation="350" chosen-class="chosen" class="drag_box">
          <transition-group>
            <div v-for="(item, index) in sureArrTemp" :key="item.prop" class="drag_item">
              <span class="drag_text">{{ item.label }}</span>
              <MoveSvg class="move icon" />
              <TopSvg @click="toTop(item, index)" class="top icon" />
              <CloseSvg @click="deleteTag(index)" class="close icon"/>
            </div>
          </transition-group>
        </m-vuedraggable>
      </div>
    </div>
  </a-modal>
</template>

<script>
import CloseSvg from '@/assets/icons/close-line.svg?inline'
import MoveSvg from '@/assets/icons/move.svg?inline'
import TopSvg from '@/assets/icons/top.svg?inline'
export default {
  name: 'CustTagsModel',
  components: { CloseSvg, MoveSvg, TopSvg },
  data () {
    return {
      targetArrTemp: [],
      sureArrTemp: []
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    targetArr: {
      type: Array,
      default: () => []
    },
    sureArr: {
      type: Array,
      default: () => []
    },
    storageName: {
      type: String,
      default: ''
    },
    defaultCheckedList: {
      default: () => ([]),
      type: Array
    }
  },
  watch: {
    visible: {
      handler (val) {
        if (!val) return
        this.targetArrTemp = JSON.parse(JSON.stringify(this.targetArr))
        this.sureArrTemp = JSON.parse(JSON.stringify(this.sureArr))
        this.setAllArr()
      },
      immediate: true
    }
  },
  computed: {
    custTagsVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    },
    allTags () {
      return this.targetArrTemp.map((item) => item.list).reduce((pre, cur) => pre.concat(cur), [])
    },
    indeterminateAll () {
      return this.allTags.some((item) => item.checked) && !this.allTags.every((item) => item.checked)
    },
    checkedAll () {
      return this.allTags.every((item) => item.checked)
    }
  },
  created () {
    const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') !== -1
    if (isFirefox) {
      document.body.ondrop = function (event) {
        event.stopPropagation()
        event.preventDefault()
      }
    }
  },
  methods: {
    changeAllSelection () {
      if (this.checkedAll) {
        this.sureArrTemp = []
      } else {
        this.sureArrTemp = this.allTags.map((item) => ({ ...item }))
      }
      this.setAllArr()
    },
    changeDefaultSelection () {
      this.sureArrTemp = this.defaultCheckedList.map((item) => ({ ...item }))
      this.setAllArr()
    },
    changeInvertSelection () {
      this.sureArrTemp = this.allTags.filter((item) => !item.checked)
      this.setAllArr()
    },
    toTop (item, index) {
      const target = this.sureArrTemp.splice(index, 1)
      this.sureArrTemp.unshift(target[0])
    },
     // 删除选中项
    deleteTag (index) {
      this.sureArrTemp.splice(index, 1)
      this.setAllArr()
    },
    /**
     * 修改单个指标
     * e     事件源
     * index  组别索引
     * c      组别中的指标索引
     */
    changeTag (e, index, c) {
      if (this.targetArrTemp[index].list[c].checked) {
        // 该指标之前是选中状态
        const prop = this.targetArrTemp[index].list[c].prop
        this.targetArrTemp[index].list[c].checked = false
        const sureIndex = this.sureArrTemp.findIndex((item) => item.prop === prop)
        this.deleteTag(sureIndex)
      } else {
        // 该指标之前是未选中状态
        this.targetArrTemp[index].list[c].checked = true
        this.sureArrTemp.push({
          ...this.targetArrTemp[index].list[c]
        })
        this.setAllArr()
      }
    },
    /**
     * 点击数据组别的全选按钮
     * e     事件源
     * index 组别索引
     */
    changeIndeter (e, index) {
      if (this.targetArrTemp[index].checkAll) {
        // 该按钮之前是全选状态
        this.targetArrTemp[index].indeterminate = false
        this.targetArrTemp[index].checkAll = false
        this.targetArrTemp[index].list.forEach((item) => {
          item.checked = false
          const prop = item.prop
          const sureIndex = this.sureArrTemp.findIndex((child, c) => child.prop === prop)
          if (sureIndex !== -1) {
            this.deleteTag(sureIndex)
          }
        })
      } else {
        // 该按钮之前不是全选状态
        this.targetArrTemp[index].indeterminate = false
        this.targetArrTemp[index].checkAll = true
        this.targetArrTemp[index].list.forEach((item) => {
          item.checked = true
          const prop = item.prop
          const sureIndex = this.sureArrTemp.findIndex((child, c) => child.prop === prop)
          if (sureIndex === -1) {
            this.sureArrTemp.push({
              ...item
            })
          }
        })
        this.setAllArr()
      }
    },
    // 修改总数组（刷新总数组）
    setAllArr () {
      const selectList = this.sureArrTemp.map((item) => item.prop)
      this.targetArrTemp.forEach((item) => {
        // 是否全选
        this.$set(
          item,
          'checkAll',
          item.list.every((child) => selectList.includes(child.prop))
        )
        this.$set(item, 'indeterminate', item.list.some((child) => selectList.includes(child.prop)) && !item.checkAll)
        this.$set(item, 'count', item.list.filter((child) => selectList.includes(child.prop)).length)
        // 指标选中
        item.list.forEach((child) => {
          this.$set(child, 'checked', selectList.includes(child.prop))
        })
      })
    },
    handleCancel () {
      this.custTagsVisible = false
    },
    handleSubmit () {
      if (this.sureArrTemp.length < 5) {
        return this.$message.warning('至少选择5个指标！')
      }
      const tagInfo = {
        targetArr: this.targetArrTemp,
        sureArr: this.sureArrTemp
      }
      localStorage.setItem(this.storageName, JSON.stringify(tagInfo))
      this.$emit('changeCustTags', this.targetArrTemp, this.sureArrTemp)
      this.custTagsVisible = false
    }
  }
}
</script>

<style lang="less" scoped>
.cust-tags-model-container {
  height: 450px;
  display: flex;
  .cust-tags-container-left {
    width: 602px;
    min-width: 602px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    border-right: 1px solid @compBorderColor;
    .cust-tags-contral{
      height: 64px;
      border-bottom: 1px solid @compBorderColor;
      padding: 0 20px 0 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      // .checkbox {
        // font-size: 12px;
      // }
      .child{
        width: calc(100% / 3);
      }
      .btn{
        cursor: pointer;
        padding: 0 5px;
        // font-size: 12px;
      }
    }
    .tag-group-wrapper {
      flex: 1 auto;
      height: 100%;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        box-shadow: inset 0 0 5px fade(@primary-color, 3%);
        background: fade(@primary-color, 20%);
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px fade(@primary-color, 3%);
        border-radius: 0;
        background: rgba(0, 0, 0, 0.1);
      }
      .tag-group-box {
        border-bottom: 1px solid @compBorderColor;
        margin-bottom: 10px;
        padding: 10px 20px 10px 50px;
        &:last-child{
          margin-bottom: 0;
          border: none;
        }
        .tag-group-head {
          height: 40px;
          line-height: 40px;
          padding: 0 20px 0 0;
          .tag-group-head-checkbox {
            font-size: 14px;
          }
        }
        .tag-group-list-box {
          position: relative;
          display: flex;
          flex-wrap: wrap;
          .checkbox_item {
            display: flex;
            align-items: center;
            width: calc(100% / 3);
            margin-left: 0;
            // padding: 20px 10px 5px 0;
            height: 39px;
            font-size: 13px;
          }
        }
      }
    }
  }
  .cust-tags-container-right {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 15px 0;
    .cust-tags-container-right-head {
      height: 30px;
      line-height: 30px;
      border-radius: 10px 10px 0 0;
      padding: 0 10px;
      font-size: 14px;
    }
    .drag_box {
      margin-top: 6px;
      flex-grow: 1;
      flex: 1;
      background-color: #fff !important;
      overflow-y: scroll;
      height: 100%;
      padding: 0 3px;
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: #dbdee5;
      }
      &::-webkit-scrollbar-track {
        border-radius: 4px;
        background: #eff0f7;
      }
      .drag_item {
        display: flex;
        align-items: center;
        width: 100%;
        height: 35px;
        margin: 0 auto;
        padding: 0 10px;
        .icon{
          cursor: pointer;
          width: 16px;
          height: 16px;
          margin-left: 4px;
        }
        .drag_text {
          flex: 1;
          text-align: left;
          font-size: 13px;
          font-weight: 400;
        }
      }
    }
  }
}
</style>

<style lang="less">
.cust-tags-modal {
  .ant-modal-body {
    padding: 0!important;
  }
  .ant-checkbox{
    .ant-checkbox-inner{
      width: 14px;
      height: 14px;
    }
  }
}
</style>
